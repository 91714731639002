<template>
  <div class="content-box">
    <div class="container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资料管理</el-breadcrumb-item>
        <el-breadcrumb-item>BOM表管理</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content">
        <div class="top">
          <div class="top_search">
            <el-form
              :inline="true"
              class="serach"
              ref="bomFrom"
              :model="bomFrom"
            >
              <div class="button-gutter">
                <div class="name_left">
                  <el-form-item label="BOM类型：">
                    <el-select
                      v-model="searchBomdata"
                      placeholder="请选择类型"
                    >
                      <el-option
                        v-for="item in bomListOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-button
                    type="primary"
                    @click="SearchType()"
                  >查询</el-button>

                  <el-button
                    style="margin-left: 15px"
                    @click="reset()"
                  >重置</el-button>
                </div>
                <div class="btn_right">
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="AddBom"
                    >新增</el-button>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="content_table">
            <el-table
              :data="tableData"
              border
              max-height="500"
              style="width: 100%"
            >
              <el-table-column
                align="center"
                width="50"
                label="序号"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="bomType"
                width="200"
                align="center"
                label="BOM类型"
              > </el-table-column>
              <el-table-column
                prop="sapNo"
                width="200"
                align="center"
                label="SAP物料编码"
              > </el-table-column>
              <el-table-column
                prop="materialType"
                width="150"
                align="center"
                label="物料类型"
              > </el-table-column>
              <el-table-column
                prop="plant"
                width="200"
                align="center"
                label="工厂"
              > </el-table-column>

              <el-table-column
                prop="materialDescribe"
                align="center"
                label="物料描述"
                width="250"
              > </el-table-column>
              <el-table-column
                prop="materialGroup"
                width="100"
                align="center"
                label="物料组"
              > </el-table-column>
              <el-table-column
                width="150"
                prop="measuringUnit"
                align="center"
                label="基本计量单位"
              > </el-table-column>

              <el-table-column
                align="center"
                width="250"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    style="margin-right: 12px"
                    @click="Reset(scope.row, scope.$index)"
                    size="mini"
                  >
                    编辑
                  </el-button>
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    confirm-button-type="danger"
                    icon="el-icon-info"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="deleteFile(scope.row)"
                  >
                    <el-button
                      type="danger"
                      size="mini"
                      slot="reference"
                      plain
                    >删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <div class="content_pagination">
              <el_pagination
                :total="total"
                :num="num"
                @page-limit="page_limit"
              ></el_pagination>
            </div>
          </div>
        </div>
        <!-- 新增BOM  -->
        <el-dialog
          title="新增BOM"
          width="900px"
          :visible.sync="dialogVisible"
          :before-close="Cancel"
        >
          <el-form
            :model="bomFrom"
            label-position="left"
          >
            <el-form-item
             
              label="BOM类型"
              required
            >
              <el-select v-model="bomFrom.bomType">
                <el-option
                  v-for="item in bomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="产品资料"
              required
              v-if="productInfo"
            >
              <el-cascader
                v-model="bomFrom.productDrawingId"
                @change="supplierChange"
                :props="productInformation"
                :options="archiveOptions"
                style="width:680px"
              >
              </el-cascader>
              <el-tooltip
                v-if="tips"
                popper-class="tps"
                effect="light"
                content="请先上传产品资料相关二维图纸或三维模型"
                placement="right"
              >
                <span style="margin-left:10px;font-size:20px">
                  <i class="el-icon-question" />
                </span>
              </el-tooltip>
            </el-form-item>
            <el-form-item
              label="SAP物料编码"
              required
            >
              <el-input
                v-model="bomFrom.sapNo"
                placeholder="请输入SAP物料编码"
              />
            </el-form-item>
            <el-form-item
              label="物料类型"
              required
            >
              <el-input
                v-model="bomFrom.materialType"
                placeholder="请输入物料类型"
              />
            </el-form-item>
            <el-form-item
              label="工厂"
              required
            >
              <el-input
                v-model="bomFrom.plant"
                placeholder="请输入工厂"
              />
            </el-form-item>
            <el-form-item
              label="旧物料编码"
              required
            >
              <el-input
                v-model="bomFrom.oldMaterialNo"
                placeholder="请输入旧物料编码"
              />
            </el-form-item>
            <el-form-item
              label="物料描述"
              required
            >
              <el-input
                v-model="bomFrom.materialDescribe"
                placeholder="请输入物料描述"
              />
            </el-form-item>
            <el-form-item
              label="物料组"
              required
            >
              <el-input
                v-model="bomFrom.materialGroup"
                placeholder="请输入物料组"
              />
            </el-form-item>
            <el-form-item
              label="基本计量单位"
              required
            >
              <el-input
                v-model="bomFrom.measuringUnit"
                placeholder="请输入基本计量单位"
              />
            </el-form-item>
          </el-form>
          <div class="footer">
            <el-button @click="Cancel">取 消</el-button>
            <el-button
              type="primary"
              @click="SendData(bomFrom)"
            >确定</el-button>
          </div>
        </el-dialog>

        <!-- 编辑BOM -->

        <el-dialog
          title="编辑BOM"
          :visible.sync="resetdialogVisible"
          :before-close="Cancel"
          width="900px"
        >
          <el-form
            :model="resetBomFrom"
            label-position="left"
          >
            <el-form-item
              label="BOM类型"
              required
            >
              <el-select v-model="resetBomFrom.bomType">
                <el-option
                  v-for="item in bomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="产品资料"
              required
              v-if="updateProductInfo"
            >
              <el-cascader
                v-model="resetBomFrom.productDrawingId"
                @change="supplierChange"
                :options="archiveOptions"
                :props="productInformation"
                style="width:680px"
              >
              </el-cascader>
              <el-tooltip
                v-if="updateTips"
                popper-class="tps"
                effect="light"
                content="请先编辑产品资料相关二维图纸或三维模型"
                placement="right"
              >
                <span style="margin-left:10px;font-size:20px">
                  <i class="el-icon-question" />
                </span>
              </el-tooltip>
            </el-form-item>
            <el-form-item
              label="SAP物料编码"
              required
            >
              <el-input
                v-model="resetBomFrom.sapNo"
                placeholder="请输入SAP物料编码"
              />
            </el-form-item>
            <el-form-item
              label="物料类型"
              required
            >
              <el-input
                v-model="resetBomFrom.materialType"
                placeholder="请输入物料类型"
              />
            </el-form-item>
            <el-form-item
              label="工厂"
              required
            >
              <el-input
                v-model="resetBomFrom.plant"
                placeholder="请输入工厂"
              />
            </el-form-item>
            <el-form-item
              label="旧物料编码"
              required
            >
              <el-input
                v-model="resetBomFrom.oldMaterialNo"
                placeholder="请输入旧物料编码"
              />
            </el-form-item>
            <el-form-item
              label="物料描述"
              required
            >
              <el-input
                v-model="resetBomFrom.materialDescribe"
                placeholder="请输入物料描述"
              />
            </el-form-item>
            <el-form-item
              label="物料组"
              required
            >
              <el-input
                v-model="resetBomFrom.materialGroup"
                placeholder="请输入物料组"
              />
            </el-form-item>
            <el-form-item
              label="基本计量单位"
              required
            >
              <el-input
                v-model="resetBomFrom.measuringUnit"
                placeholder="请输入基本计量单位"
              />
            </el-form-item>
          </el-form>
          <div
            slot="footer"
            class="footer"
          >
            <el-button @click="Cancel">取 消</el-button>
            <el-button
              type="primary"
              @click="ResetData(resetBomFrom)"
            >确定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { bomlist, bomType, addBomList, resetBomList, deleteBomList } from '@/api/document';
import { fileListType, archiveManagementBomList } from '@/api/product';
export default {
    name: 'Bom',
    components: {},
    data() {
        return {
            productInfo: true,
            updateProductInfo:true,
            productInformation: {
                expandTrigger: 'hover'
            },
            resetProduct: '',
            tips: true,
            updateTips:true,
            num: 0,
            dialogVisible: false,
            resetdialogVisible: false,
            tableData: [],
            bomFrom: {
                // materialType: "ZHB",
                // plant: "1201",
                // materialGroup: "风轮系统",
                // oldMaterialNo: "暂无",
                // measuringUnit: "个",
            },
            archiveOptions: [
                {
                    label: '',
                    value: '',
                    children: [
                        {
                            label: '',
                            value: '',
                            children: [
                                {
                                    label: '',
                                    value: ''
                                }
                            ]
                        }
                    ]
                }
            ],
            resetBomFrom: {},
            fileList: [],
            bomListOptions: [],
            bomOptions: [
                { value: 'EBOM', label: 'EBOM' },
                { value: '维修BOM', label: '维修BOM' },
                { value: 'MBOM', label: 'MBOM' }
            ],

            datas: {
                parent: 0,
                other: {},
                timeUpdated: '',
                timeCreated: '',
                id: '',
                type: 'xls'
            },
            time1: '',
            actions: '/api/document/upload' + '?' + 'type=' + 'BOM' + '&' + 'header=' + '1',

            pageSize: 10,
            currentPage: 1,
            total: 0,
            bomType: null,
            searchBomdata: null
        };
    },
    watch: {
        nowType(newValue, oldValue) {
            console.log('新值', newValue);
            console.log('旧值', oldValue);
            this.oldType = oldValue;
        },
        'bomFrom.productDrawingId'(newVal, oldVal) {
            if (newVal) {
                this.tips = false;
            } else {
                this.tips = true;
            }
        },
        'bomFrom.bomType'(newVal,oldVal){
          if(newVal==='维修BOM'){
            this.productInfo=false
          }else{
            this.productInfo=true
          }
        },
        'resetBomFrom.bomType'(newVal,oldVal){
          if(newVal==='维修BOM'||oldVal==='维修BOM'){
            this.updateProductInfo=false
          }else{
            this.updateProductInfo=true
          }
        },
        'resetBomFrom.productDrawingId'(newVal,oldVal){
          if(oldVal===newVal||oldVal===undefined){
            this.updateTips=true
          }else{
            this.updateTips=false
          }
        }
    },
    created() {
        function getNowTime() {
            let now = new Date();
            let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
            let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
            let today = now.getDate(); //获取当前日(1-31)
            let hour = now.getHours(); //获取当前小时数(0-23)
            let minute = now.getMinutes(); //获取当前分钟数(0-59)
            let second = now.getSeconds(); //获取当前秒数(0-59)
            let nowTime = '';
            nowTime =
                year +
                '-' +
                fillZero(month) +
                '-' +
                fillZero(today) +
                ' ' +
                fillZero(hour) +
                ':' +
                fillZero(minute) +
                ':' +
                fillZero(second);
            // this.datas.timeCreated = nowTime
            return nowTime;
        }

        function fillZero(str) {
            var realNum;
            if (str < 10) {
                realNum = '0' + str;
            } else {
                realNum = str;
            }
            return realNum;
        }
        this.time1 = getNowTime();
    },
    mounted() {
        this.getFileList();
        this.getBomType();
        this.getProductList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        hidepanel(event) {
            let sp = document.getElementsByClassName('content_searchItem');
            if (sp) {
                if (!sp.contains(event.target)) {
                    this.getFileList();
                }
            }
        },
        async getProductList() {
            let res = await archiveManagementBomList();
            if (res.code == 200) {
                const data = res.data;
                console.log(data);
                data.forEach((item) => {
                    const children = item['children'];
                    const arr = [];
                    children.forEach((chil) => {
                        if (chil['label'] === '二维图纸' || chil['label'] === '三维模型') {
                            arr.push(chil);
                        }
                    });
                    item.children = arr;
                });
                this.archiveOptions = data;
            }
        },
        //获取表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await bomlist({
                limit,
                page,
                bomType: this.bomType
            });
            if (res.code == 200) {
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },

        // 获取BOM 类型
        async getBomType() {
            let res = await bomType();
            if (res.code == 200) {
                //   console.log(res.data);
                let Data = res.data;
                let list = Array.from(new Set(Data));
                //  console.log(list)
                let listoption = [];
                list.forEach((item) => {
                    let dataoption = {};
                    dataoption.value = item;
                    dataoption.label = item;
                    listoption.push(dataoption);
                });
                this.bomListOptions = listoption;
                console.log(this.bomListOptions);
            }
        },
        // 新增BOM表

        async SendData(bomFrom) {
            let res = await addBomList(bomFrom);
            if (res.code == 200) {
                this.dialogVisible = false;
                this.bomFrom = {};
                this.archiveOptions = [];
                this.$message.success('新增成功');
                this.total = res.total;
                this.getFileList();
                this.getBomType();
            } else {
                this.$message.error(res.message);
            }
        },
        //删除BOM
        async deleteFile(row) {
            let id = row.id;

            const res = await deleteBomList({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getBomType();
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        //新增弹框
        AddBom() {
            this.dialogVisible = true;
        },
        //编辑弹框
        Reset(row, $index) {
            console.log(row);
            this.resetdialogVisible = true;
            // this.resetBomFrom.id = row.id;
            this.resetBomFrom = JSON.parse(JSON.stringify(row));
        },
        // 确定编辑
        async ResetData(resetBomFrom) {
            console.log(resetBomFrom);
            let res = await resetBomList(resetBomFrom);
            if (res.code == 200) {
                this.resetdialogVisible = false;
                this.resetBomFrom = {};
                this.$message.success('编辑成功');
                this.total = res.total;
                this.getFileList();
                this.getBomType();
            } else {
                this.$message.error(res.message);
            }
        },
        //取消新增
        Cancel() {
            this.dialogVisible = false;
            this.resetdialogVisible = false;
            this.resetBomFrom = {};
            this.bomFrom = {};
        },

        imports() {
            this.imports_show = !this.imports_show;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        successw(response, file, fileList) {
            console.log(response);
            if (response.code == 200) {
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
                this.fileList = [];
                this.getFileList(this.currentPage, this.pageSize);
            } else {
                this.$message.error('上传失败');
            }
        },
        uploads(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
            const extension3 = testmsg === 'xlsx';
            this.extension3 = extension3;
            const extension4 = testmsg === 'xls';
            this.extension4 = extension4;
            // const isLt2M1 = file.size / 1024 < 500
            // this.isLt2M1 = isLt2M1
            if (!this.extension3 && !this.extension4) {
                this.$message({
                    message: '上传文件只能是excel格式!',
                    type: 'warning'
                });
            }

            return this.extension3 || this.extension4;
        },
        newhand(event, file, fileList) {
            console.log(event);
        },
        blur() {
            console.log(this.$refs[cascader]);
        },

        async SearchType(nowType) {
            this.bomType = this.searchBomdata;
            this.getFileList();
        },
        reset() {
            (this.bomType = null), (this.searchBomdata = null);
            this.getFileList();
        },
        supplierChange(res) {
            // this.contractManagementCreateDto['supplierId'] = res[res.length - 1]
            this.bomFrom['productDrawingId'] = res[2];
            this.resetBomFrom['productDrawingId'] = res[2];
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.tests {
    margin: -40px 0px 0px 120px;
}

.table-paging {
    right: 60px;
    position: absolute;
}
.content {
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.content_main {
    width: 100%;
    height: 80px;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 20%;

    margin: 0 auto;
}
.container {
    overflow: hidden;
}
::v-deep .el-cascader {
    margin-left: 4px;
}
</style>