import request from "@/utils/request";

export function productCreate(data) {
    return request({
        url: '/product/create',
        method: 'post',
        data
    })
}



//   产品表

export function productList(data) {
    return request({
      url: 'product/list',
      method: 'GET',
      params: data
    })
  }
  //   生产资料表
  
  export function meansProductionuc(data) {
    return request({
      url: 'productionMaterial/list',
      method: 'GET',
      params: data
    })
  }
  // 生产资料新增
  
  export function addMeansProductionuct(data) {
    return request({
      url: 'productionMaterial/create',
      method: 'POST',
      data
    })
  }
  // 生产资料编辑
  
  export function resetMeansProductionuct(data) {
    return request({
      url: 'productionMaterial/update',
      method: 'POST',
      data
    })
  }
  
  // 生产资料编辑
  
  export function deleteProductionuctList(data) {
    return request({
      url: 'productionMaterial/remove',
      method: 'POST',
      params: data
    })
  }



 // 生产进度表

export function ProductionList(data) {
    return request({
      url: 'productionSchedule/list',
      method: 'GET',
      params: data
    })
  }
  
  // 新增生产进度
  export function addProductionschedule(data) {
    return request({
      url: 'productionSchedule/create',
      method: 'POST',
      data
    })
  }
  //生产进度编辑
  export function resetProductionschedule(data) {
    return request({
      url: 'productionSchedule/make/update',
      method: 'POST',
      data
    })
  }
  //生产进度删除
  export function deleteProductionschedule(data) {
    return request({
      url: 'productionSchedule/remove',
      method: 'POST',
      params: data
    })
  }

  //生产记录
  export function ProListUpdate(data) {
    return request({
      url: '/productionSchedule/record/update',
      method: 'POST',
    data
    })
  }
  
   //结果评价表


 export function evaluateList(data) {
  return request({
    url: 'orderComment/list',
    method: 'GET',
    params: data
  })
}



//  结果评价创建

export function  evaluateListAdd(data) {
  return request({
      url: 'orderComment/create',
      method: 'post',
      data
      
  })
}

//评价更新

export function  evaluateReset(data) {
  return request({
      url: 'orderComment/update',
      method: 'post',
      data
      
  })
}


// 结果评价删除
export function  evaluateListDelete(data) {
  return request({
      url: 'orderComment/remove',
      method: 'post',
      params:data
      
  })
}
//上归档传文件
export function productUpdate(data) {
    return request({
        url: '/product/update',
        method: 'post',
        data
    })
}

 // 归档表查询
   export function fileList(data) {
    return request({
      url: '/archiveManagement/list',
      method: 'GET',
      params: data
    })
  }

 // 归档表查询 所有分类数据
 export function fileListType(data) {
  return request({
    url: 'archiveManagement/product/type/list',
    method: 'GET',
    params: data
  })
}

    // 归档表删除

    export function  deleteFileList(data) {
      return request({
          url: 'archiveManagement/remove',
          method: 'post',
          params:data
          
      })
    }

export function productDelete(id) {
    return request({
        url: '/product/delete',
        method: 'post',
        params: {
            id: id
        }
    })
}
export function archiveManagementBomList() {
  return request({
      url: '/archiveManagement/bom/list',
      method: 'get'
  })
}